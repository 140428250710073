<template>
  <div class="slider-block">
    <div class="wrapper">
      <h2 v-if="title" class="text-subheader">{{ title }}</h2>
    </div>

    <div class="slider-block__inner">
      <ClientOnly>
        <Swiper
          :modules="[Pagination, Navigation]"
          :breakpoints="breakpoints"
          :slides-per-view="1.2"
          :space-between="16"
          :loop="media.length > 1"
          centered-slides
          navigation
          pagination
          watch-slides-progress
        >
          <SwiperSlide
            v-for="(slide, index) in media"
            :key="`slider_block_${index}`"
            v-slot="{ isVisible }"
            @click="openMediaModal(index)"
          >
            <UIPictureTag
              :image-seo="slide.image_seo"
              :image-webp="`${url}${slide.image.webp}`"
              :image-original="`${url}${slide.image.source}`"
              :is-visible="isVisible"
            />
          </SwiperSlide>
        </Swiper>
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import './styles.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import type { BlockSliderMedia } from '@/types/pages';
import { computed, onUnmounted, useMediaModal, useRuntimeConfig } from '#imports';
import UIPictureTag from '~/components/UI/picture/UIPictureTag.vue';

interface Props {
  title: string;
  media: BlockSliderMedia[];
}

const url = useRuntimeConfig().public.siteUrl;

const props = defineProps<Props>();

const breakpoints = {
  1024: {
    slidesPerView: 2,
    spaceBetween: 56,
  },
};

const dataSource = computed(() =>
  props.media.map(item => {
    if (item.link.length > 0) {
      return {
        html: `<iframe src="${item.link}?rel=0"
                    frameborder="0"
                    allow="
                     accelerometer;
                     autoplay;
                     clipboard-write;
                     encrypted-media;
                     gyroscope;
                     picture-in-picture;
                     web-share"
                    allowfullscreen>
                   </iframe>`,
      };
    }

    return {
      src: `${url}${item.image.original}`,
      alt: item.image_seo.image_alt,
      title: item.image_seo.image_title,
    };
  }),
);

const { destroyMediaModal, openMediaModal } = useMediaModal(dataSource.value);

onUnmounted(() => destroyMediaModal());
</script>

<style lang="scss">
.pswp {
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto !important;
    height: auto !important;
    display: block;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  iframe {
    margin: auto;
    display: block;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 1200px;
    width: 100%;
    height: 56.25%; //16:9
  }
}
</style>
